<template>
  <div class="users">
    <div class="content">
      <div class="tit">
        <el-input
          v-model="selectTxt"
          placeholder="请输入姓名搜索"
          style="width: 300px"
        ></el-input>
        <el-select clearable v-model="protype" placeholder="请选择用户类型">
          <el-option
            v-for="item in typelist"
            :key="item.id"
            :label="item.typename"
            :value="item.id">
          </el-option>
        </el-select>
        <el-select clearable v-model="value" placeholder="请选择检察院">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.procuratoratename"
            :value="item.id">
          </el-option>
        </el-select>
        <el-button
          slot="reference"
          @click="departSelect"
          type="primary"
          class="style_button style_button_Sel"
          icon="el-icon-search"
          >搜索</el-button>
        <el-button class="box submit" @click="editAndAddMaterial()">添加用户</el-button>
      </div>
      <div class="table">
        <el-table
          :data="tableData"
          style="width: 100% "
          :header-cell-style="{textAlign:'center'}"
          :cell-style="{textAlign:'center'}">
          <el-table-column
            type="index"
            label="序号"
            width="120">
          </el-table-column>
          <el-table-column
            prop="name"
            label="姓名"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
            prop="phone"
            label="手机号"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.isstop"
                  @change="changeStatus($event,scope.row)">
                </el-switch>
              </template>
          </el-table-column>
          <el-table-column
            prop="procuratoratename"
            label="所属检察院"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
            prop="typename"
            label="用户类型"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="editAndAddMaterial(scope.row)" type="text" size="small" icon="el-icon-edit">编辑</el-button>
              <el-button type="text" size="small" icon="el-icon-delete" @click="deluser(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-size="page"
            :pager-count="9"
            layout="total, prev, pager, next, jumper"
            :total="total"
            hide-on-single-page>
          </el-pagination>
      </div>
      <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" width="680px">
        <el-form :model="form" :rules="rules" ref="form">
            <el-form-item label="姓名" :label-width="formLabelWidth"
            prop="name">
                <el-input v-model="form.name" placeholder="请输入姓名" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="用户性别" :label-width="formLabelWidth">
                <el-select v-model="form.sex" clearable placeholder="请选择用户性别">
                  <el-option
                    v-for="item in sexs"
                    :key="item.id"
                    :label="item.sex"
                    :value="item.id">
                  </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="手机号" :label-width="formLabelWidth"
            prop="phone">
                <el-input v-model="form.phone" placeholder="请输入手机号" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="所属检察院" :label-width="formLabelWidth" size="medium">
                <el-select v-model="form.pro" clearable placeholder="请选择所属检察院">
                  <el-option
                    v-for="item in prolist"
                    :key="item.id"
                    :label="item.procuratoratename"
                    :value="item.id">
                  </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="用户类型" :label-width="formLabelWidth"
            prop="type">
                <el-select v-model="form.type" clearable placeholder="请选择用户类型">
                  <el-option
                    v-for="item in usertype"
                    :key="item.id"
                    :label="item.typename"
                    :value="item.id">
                  </el-option>
                </el-select>
            </el-form-item>
            <el-form-item class="dialog-footer">
              <el-button @click="dialogFormVisible = false">取 消</el-button>
              <el-button type="primary" @click="makeSure('form')">确 定</el-button>
            </el-form-item>
        </el-form>
        <!-- <span slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="makeSure()">确 定</el-button>
        </span> -->
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {getUserTypes,getVisitsList,getUserAll,delUser,getUserOne,updateUserState,addUserOne} from '@/utils/api'
import { Message } from 'element-ui';
export default {
  inject: ["reload"],
    data(){
        return{
            tableData: [],
            sexs:[
              {
                id:0,
                sex:'女',
              },
              {
                id:1,
                sex:'男',
              },
            ],
            prolist:[],
            usertype:[],
            selectTxt:'',
            options: [],
            typelist:[],
            protype:'',
            value: '',
            dialogTitle:'',
            dialogFormVisible:false,
            form:{
                name:'',
                sex:'',
                phone:'',
                type:'',
                pro:''
            },
            rules: {
              name: [
                { required: true, message: '请输入姓名', trigger: 'blur' }
              ],
              phone: [
                { required: true,pattern:/^[1-9]\d{10}$/, message: '请输入正确的手机号格式', trigger: 'blur' }
              ],
              type: [
                { required: true, message: '请选择用户类型', trigger: 'blur' }
              ],
            },
            formLabelWidth: '100px',
            idx:'',
            currentPage4: 1,
            total:0,
            page:10,
        }
    },
    methods:{
      // 获取下拉框
      gettypes(){
        getUserTypes().then(res =>{
          // console.log(res);
          this.typelist=res.data.data
          this.usertype = res.data.data
        })
        let data = {
          pid:'',   //检察院id
          did:'',   //案件类型id
          vid:'',   //审核状态id
          userId:'',   //用户
          phone:'',   //手机号
          startsTime:'',   //开始时间
          endTime:'',   //结束时间
          limit:this.currentPage4,  //第几页
          page:this.page,  //每页数量
        }
        getVisitsList(data).then(res =>{
          // console.log(res);
          this.options = res.data.data.userId
          this.prolist = res.data.data.userId
        })
      },
      // 获取列表
      getuserlist(){
        // console.log(this.protype,this.value);
        let data = {
          typesId:this.protype, //用户类型id
          proId:this.value, //检察院id
          name:this.selectTxt,  //搜索框内容
          limit:this.currentPage4,  //第几页
          page:this.page,  //每页数量
        }
        getUserAll(data).then(res =>{
          // console.log('列表',res);
          res.data.data.list.map(item =>{
            if(item.isstop == 0){
              item.isstop = true
            }else if(item.isstop == 1){
              item.isstop = false
            }
          })
          this.tableData = res.data.data.list
          if(res.data.data.total.length !== 0){
            this.total = res.data.data.total[0].total
          }
        })
      },
      // 回显
      gettext(id){
        getUserOne({
          userId:id
        }).then(res =>{
          // console.log(res);
          this.form.name=res.data.data.username //姓名
          this.form.phone=res.data.data.phone //手机号
          this.form.type=res.data.data.typeid   //用户类型id
          this.form.sex=res.data.data.sex //性别
          this.form.pro=res.data.data.procuratoratename //单位id
        })
      },
      // 搜索
      departSelect(){
        this.getuserlist()
      },
      handleCurrentChange(val) {
        // console.log(`当前页: ${val}`);
        this.currentPage4 = val
        // console.log(this.currentPage4);
        this.getuserlist()
      },
      // 改变状态
      changeStatus(e,row){
        // console.log(e)
        if(e == true){
          var stue = 0
        }else if(e == false){
          var stue = 1
        }
        updateUserState({
          userId:row.id,
          staticId:stue
        }).then(res =>{
          // console.log(res);
          if(res.data.code == 200){
            Message({ message:'修改状态成功',type:"success" })
            this.reload();
          }
        })
      },
      // 删除用户
      deluser(row){
        this.$confirm("是否删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        }).then(() => {
          delUser({
            userId:row.id
          }).then(res =>{
            if(res.data.code == 200){
              Message.success("删除成功！");
              this.reload();
            }
          });
        })
        .catch(() => {
          Message.info("已取消删除!");
        });
      },
      // 报存
      save(data){
        addUserOne(data).then(res =>{
          // console.log(res);
          if(res.data.code == 200){
            if (this.ifMaterialEdit == 1){
              Message({ message:'保存成功',type:"success" })
            }else if(this.ifMaterialEdit == 0){
              Message({ message:'修改成功',type:"success" })
            }
            this.reload();
          }
        })
      },
      // 提交
      makeSure(form) {
        this.$refs[form].validate((valid) => {
          if (valid) {
            let data = {
              id:this.idx,  //编辑点击的id
              username: this.form.name, //姓名
              sex: this.form.sex, //性别
              phone: this.form.phone, //手机号
              procuratoratename: this.form.pro, //单位id
              typeid: this.form.type, //用户类型id
            }
            // 新增接口对接
            if (this.ifMaterialEdit == 1) {
              // console.log('新增')
              this.save(data)
            } else if (this.ifMaterialEdit == 0) {
              // 编辑接口对接
              // console.log('编辑')
              this.save(data)
            }
            this.dialogFormVisible = false
          } else {
            // console.log('error submit!!');
            return false;
          }
        });
      },
      // 修改添加
      editAndAddMaterial(row) {
        if (row) {
          this.ifMaterialEdit = 0
          this.dialogFormVisible = true
          this.dialogTitle = '编辑用户'
          // console.log(row)
          this.gettext(row.id)
          this.idx=row.id
        } else {
          // console.log(this.idx);
          this.ifMaterialEdit = 1
          this.dialogFormVisible = true
          this.dialogTitle = '新增用户'
          this.form.name=''
          this.form.phone=''
          this.form.type=''
          this.form.sex=''
          this.form.pro=''
          this.idx=''
        }
      },
    },
    mounted(){
      this.gettypes()
      this.getuserlist()
    }
}
</script>

<style  lang="less" scoped>
.users{
  min-height: 95%;
  background: #FFFFFF;
  box-shadow: 0px 3px 20px 0px rgba(131,131,131,0.1800);
  border-radius: 4px;
  padding: 24px;
  box-sizing: border-box;
}
.block{
  margin-top: 20px;
}
.el-pagination {
    text-align: center; 
}
.dialog-footer{
  display: flex;
  justify-content: flex-end;
}

.table{
  margin-top: 20px;
}
::v-deep .el-dialog .el-input__inner {
  width: 450px !important;
}
.tit{
  display: flex;
}
::v-deep .tit .el-input,.el-button,.el-select {
  margin-right: 15px !important;
}
</style>